import React from 'react'
import logo from '../images/aetna-clearlink-cobranded-logo.svg'
import data from '../data/footerData'
import findDisclaimers from '../../../../utils/findDisclaimers'

const footer = (selectedBrandyReferences, allBrandyReferences) => {
  const disclaimerData = findDisclaimers(
    selectedBrandyReferences,
    allBrandyReferences
  )
  return {
    linkGroups: data,
    logo: <img src={logo} alt="Logo" />,
    logoLink: '/',
    navigationTextColor: 'primary',
    logoBottomBorder: 'lightGray',
    disclaimers: disclaimerData,
  }
}

export default footer
