/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import coupleAtThemePark from '../../images/heroes/couple-at-theme-park.jpg'

import { Phone } from 'emotion-icons/material'
import { StaticImage } from 'gatsby-plugin-image'

import {
  Columns,
  Column,
  Hero,
  LinkButton,
  List,
  ListItem,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/FooterPPC'
import header from '../../components/HeaderPPC'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()

  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-footer1',
    'disclaimer-amd-medicareadvantagelp',
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title: ' Aetna Medicare ',
          description: '',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/lp/medicare-advantage',
        promoCode: '110752',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Aetna Medicare ',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero background-image-position-right"
              image={
                <img
                  src={coupleAtThemePark}
                  alt="elderly couple visits a theme park and smiles"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1" color="dark">
                    Take your benefits up a level with Aetna Medicare Advantage{' '}
                  </Typography>

                  <Typography variant="h4" color="dark">
                    Plans starting with premiums as low as $0.{' '}
                  </Typography>

                  <Typography variant="body" color="dark">
                    Aetna Medicare Advantage, or Medicare Part C, is your
                    gateway to private Medicare insurance that goes above and
                    beyond the basics. Aetna® plans cover a range of healthcare
                    and lifestyle essentials, such as:
                  </Typography>

                  <List>
                    <ListItem>Prescription drug coverage</ListItem>
                    <ListItem>Routine dental care</ListItem>
                    <ListItem>Hearing aids</ListItem>
                    <ListItem>Fitness memberships</ListItem>
                  </List>

                  <div className="card bg-light box-shadow">
                    <Typography variant="h4">
                      Speak with a licensed agent{' '}
                    </Typography>
                    <LinkButton
                      variant="feature"
                      to={`tel:${rotatedNumber}`}
                      color="primary"
                      className="margin-x-auto"
                      leftIcon={<Phone />}
                    >
                      {rotatedNumber}
                    </LinkButton>
                  </div>
                </>
              }
            />
          </div>
        </div>

        <VariableContent backgroundColor="lightGray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-card.svg"
                alt="card icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                What is Aetna Medicare Advantage?
              </Typography>
              <Typography variant="body">
                Medicare Advantage Plans, sometimes called "Part C" or "MA
                Plans," are an “all in one” alternative to Original Medicare.
                They are offered by private companies approved by Medicare. If
                you join a Medicare Advantage Plan, you still have Medicare.
                These "bundled" plans include Medicare Part A (Hospital
                Insurance) and Medicare Part B (Medical Insurance). They also
                usually include Medicare drug coverage (Part D).
              </Typography>
              <Typography variant="body">
                Aetna Medicare Advantage covers everything in a traditional
                Medicare Parts A and B program. And often adds other benefits.
                Read on to learn about the Aetna difference:
              </Typography>
            </Column>
          </Columns>
          <Stack>
            <Columns>
              <Column className="card box-shadow center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-hospital.svg"
                    alt="hospital icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h6" color="primary">
                    Hospital Coverage
                  </Typography>
                  <Typography variant="body">
                    Medicare Advantage covers the same health services as
                    Medicare Part A, including:
                  </Typography>
                  <List>
                    <ListItem>Inpatient hospital stays</ListItem>
                    <ListItem>Nursing facility care</ListItem>
                    <ListItem>Home health services</ListItem>
                    <ListItem>Hospice care</ListItem>
                  </List>
                </div>
              </Column>
              <Column className="card box-shadow center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-heart-shield.svg"
                    alt="heart-shield icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h6" color="primary">
                    Medical Coverage
                  </Typography>
                  <Typography variant="body">
                    Medicare Advantage covers the same health services as
                    Medicare Part B, including:
                  </Typography>
                  <List>
                    <ListItem>Outpatient care</ListItem>
                    <ListItem>Preventive services</ListItem>
                    <ListItem>Mental health services</ListItem>
                  </List>
                </div>
              </Column>
              <Column className="card box-shadow center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-card-plus.svg"
                    alt="card-plus icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h6" color="primary">
                    Added Benefits
                  </Typography>
                  <Typography variant="body">
                    Medicare Advantage includes coverage of both Medicare Parts
                    A and B, plus added benefits:
                  </Typography>
                  <List>
                    <ListItem>Vision</ListItem>
                    <ListItem>Dental</ListItem>
                    <ListItem>Hearing</ListItem>
                    <ListItem>Health & wellness</ListItem>
                    <ListItem>Emergency care while abroad</ListItem>
                    <ListItem>Gym memberships</ListItem>
                  </List>
                </div>
              </Column>
            </Columns>
          </Stack>
          <br />
          <br />
          <Typography variant="body">
            Aetna has been around for quite some time. Maybe your last employer
            offered Aetna coverage. Or maybe a friend or family member has
            previously enrolled in Aetna coverage. You may recognize them, as
            they’ve helped millions of Americans enroll in Aetna Medicare plans
            to date.
          </Typography>
          <Typography variant="body">
            With a trusted name, wide coverage, and exclusive benefits, the
            Aetna name brings you so much more than Medicare.
          </Typography>
        </VariableContent>

        <VariableContent
          id="ready-to-enroll"
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">
                Ready to enroll? Choose one of three, easy steps:
              </Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column className="card box-shadow center-content bg-light">
                <div className="top-content">
                  <Typography variant="h6" color="primary">
                    Speak to a licensed agent
                  </Typography>
                  <StaticImage
                    src="../../images/icons/purple-phone.svg"
                    alt="phone icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="body">
                    Call an agent today for a free consultation.
                    <br />
                    TTY: 711, available 24/7
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  className="margin-x-auto"
                >
                  Call {rotatedNumber}
                </LinkButton>
              </Column>
              <Column className="card box-shadow center-content bg-light">
                <div className="top-content">
                  <Typography variant="h6" color="primary">
                    Submit a request form
                  </Typography>
                  <StaticImage
                    src="../../images/icons/purple-checklist.svg"
                    alt="checklist icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="body">
                    Fill out a request form to begin the enrollment process in
                    the plan that's right for you.
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/form"
                  color="primary"
                  className="margin-x-auto"
                >
                  Fill out a form
                </LinkButton>
              </Column>
              <Column className="card box-shadow center-content bg-light">
                <div className="top-content">
                  <Typography variant="h6" color="primary">
                    Schedule a call for later!
                  </Typography>
                  <StaticImage
                    src="../../images/icons/purple-calendar.svg"
                    alt="calendar icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="body">
                    Is now not a good time? Let us call you on your schedule.
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/schedule"
                  color="primary"
                  className="margin-x-auto"
                >
                  Schedule a call today
                </LinkButton>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
