import React from 'react'
import {
  Typography,
  useMapi,
  LeshenPhoneCTA,
} from '@leshen/gatsby-theme-leshen'
import logo from '../images/aetna-clearlink-cobranded-logo.svg'
import topBar from '../data/topBarData'

const header = () => {
  const { rotatedNumber } = useMapi() // eslint-disable-line
  const newHeaderData = {
    navigationPosition: 'Under logo',
    navigationBackgroundColor: 'primary',
    headerBackgroundColor: 'white',
    navigationTextColor: 'white',
    hideLocation: true,
    topBarLinks: topBar,
    topBarBackgroundColor: 'background1',
    textBlock: (
      <>
        <LeshenPhoneCTA
          to={`tel:${rotatedNumber}`}
          color="primary"
          className="margin-left-auto"
          leftIcon=""
          cobra="Swap with Cobra CTA"
        >
          TTY 711 |
        </LeshenPhoneCTA>
        <Typography variant="">Speak with a licensed agent</Typography>
        <Typography variant="">
          M-F 6:00 a.m. to 5 p.m. MDT, Closed Weekends
        </Typography>
      </>
    ),
    textBlockAlignment: 'right',
    logo: <img src={logo} alt="Logo" />,
    logoLink: '/',
  }

  return newHeaderData
}

export default header
